.suspense-fallback {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    padding-right: 5px;
    font-size: 1.3rem;
  }
}
